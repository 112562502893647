<template>
  <Container :title="$t('dashboardPage.dashboard')" class="dashboard">
    <v-row>
      <v-col cols="12" md="6">
        <v-card elevation="2">
          <v-card-title>
            {{ $t('dashboardPage.availableFunds') }}
            <v-spacer/>
            <v-tooltip bottom max-width="400">
              <template #activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ $t('dashboardPage.availableFundsDescription') }}</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class="display-1"><base-money :amount="balance || 0"/></v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card elevation="2" :loading="investmentsLoading">
          <v-card-title>
            {{ $t('dashboardPage.investedFunds') }}
            <v-spacer/>
            <v-tooltip bottom max-width="400">
              <template #activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ $t('dashboardPage.investedFundsDescription') }}</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class="display-1"><base-money :amount="investments.total || 0"/></v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" order="first">
        <v-row>
          <v-col>
            <v-card elevation="2" :loading="portfolioLoading">
              <v-card-title>{{ $t('dashboardPage.portfolioPerformance') }}</v-card-title>
              <v-list>
                <v-list-item>
                  <v-list-item-subtitle>{{ $t('dashboardPage.totalInvestments') }}</v-list-item-subtitle>
                  <v-list-item-title><base-money :amount="investments.total || 0"/></v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>
                    {{ $t('dashboardPage.returnedPrincipalPrimaryMarket') }}
                    <v-tooltip bottom max-width="400">
                      <template #activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{ $t('dashboardPage.returnedPrincipalPrimaryMarketDescription') }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                  <v-list-item-title><base-money :amount="portfolio.principal || 0"/></v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>
                    {{ $t('dashboardPage.returnedPrincipalSecondaryMarket') }}
                    <v-tooltip bottom max-width="400">
                      <template #activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{ $t('dashboardPage.returnedPrincipalSecondaryMarketDescription') }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                  <v-list-item-title>0,00 &euro;</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>
                    {{ $t('dashboardPage.earnedInterest') }}
                    <v-tooltip bottom max-width="400">
                      <template #activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{ $t('dashboardPage.earnedInterestDescription') }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                  <v-list-item-title><base-money :amount="portfolio.interest || 0"/></v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>
                    {{ $t('dashboardPage.earnedPenaltyInterest') }}
                    <v-tooltip bottom max-width="400">
                      <template #activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{ $t('dashboardPage.earnedPenaltyInterestDescription') }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                  <v-list-item-title><base-money :amount="portfolio.penalty || 0"/></v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>
                    {{ $t('dashboardPage.earnedOtherFees') }}
                    <v-tooltip bottom max-width="400">
                      <template #activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          small
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{ $t('dashboardPage.earnedOtherFeesDescription') }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                  <v-list-item-title><base-money :amount="portfolio.other || 0"/></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ExpectedReturns/>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="order-2">
        <v-card elevation="2" :loading="investmentsLoading">
          <v-card-title>{{ $t('dashboardPage.investmentsAllocation') }}</v-card-title>
          <v-list>
            <v-list-item :to="{ name: 'DashboardDetail', params: { id: 'current', back: true } }">
              <v-list-item-subtitle>
                {{ $t('dashboardPage.currentUpToDate') }}
                <v-tooltip bottom max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('dashboardPage.currentUpToDateDescription') }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
              <v-list-item-title><base-money :amount="investments.current || 0"/></v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'DashboardDetail', params: { id: 'in_grace', back: true } }">
              <v-list-item-subtitle>
                {{ $t('dashboardPage.gracePeriod') }}
                <v-tooltip bottom max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ $t('dashboardPage.gracePeriodDescription') }}
                  </span>
                </v-tooltip>
              </v-list-item-subtitle>
              <v-list-item-title><base-money :amount="investments.in_grace || 0"/></v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'DashboardDetail', params: { id: 'overdue_15', back: true } }">
              <v-list-item-subtitle>
                {{ $t('dashboardPage.overdue_15') }}
                <v-tooltip bottom max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('dashboardPage.overdue_15Description') }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
              <v-list-item-title><base-money :amount="investments.overdue_15 || 0"/></v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'DashboardDetail', params: { id: 'overdue_16_30', back: true } }">
              <v-list-item-subtitle>
                {{ $t('dashboardPage.overdue_16_30') }}
                <v-tooltip bottom max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('dashboardPage.overdue_16_30Description') }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
              <v-list-item-title><base-money :amount="investments.overdue_16_30 || 0"/></v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'DashboardDetail', params: { id: 'overdue_31_60', back: true } }">
              <v-list-item-subtitle>
                {{ $t('dashboardPage.overdue_31_60') }}
                <v-tooltip bottom max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('dashboardPage.overdue_31_60Description') }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
              <v-list-item-title><base-money :amount="investments.overdue_31_60 || 0"/></v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'DashboardDetail', params: { id: 'overdue_60', back: true } }">
              <v-list-item-subtitle>
                {{ $t('dashboardPage.overdue_60') }}
                <v-tooltip bottom max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('dashboardPage.overdue_60Description') }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
              <v-list-item-title><base-money :amount="investments.overdue_60 || 0"/></v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'DashboardDetail', params: { id: 'buyback', back: true } }">
              <v-list-item-subtitle>
                {{ $t('dashboardPage.buyback') }}
                <v-tooltip bottom max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('dashboardPage.buybackDescription') }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
              <v-list-item-title><base-money :amount="investments.buyback || 0"/></v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'DashboardDetail', params: { id: 'in_recovery', back: true } }">
              <v-list-item-subtitle>
                {{ $t('dashboardPage.inRecovery') }}
                <v-tooltip bottom max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('dashboardPage.inRecoveryDescription') }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
              <v-list-item-title><base-money :amount="investments.in_recovery || 0"/></v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'DashboardDetail', params: { id: 'defaulted', back: true } }">
              <v-list-item-subtitle>
                {{ $t('dashboardPage.default') }}
                <v-tooltip bottom max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('dashboardPage.defaultDescription') }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
              <v-list-item-title><base-money :amount="investments.defaulted || 0"/></v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'MyInvestments' }">
              <v-list-item-subtitle>
                {{ $t('dashboardPage.totalInvestments') }}
                <v-tooltip bottom max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('dashboardPage.totalInvestmentsDescription') }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
              <v-list-item-title><base-money :amount="investments.total || 0"/></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </Container>
</template>

<script>
import Vue from 'vue'
import ExpectedReturns from "../components/ExpectedReturns.vue"
import { EventBus } from '@/wsupdate/eventbus'

export default Vue.extend({
  name: "Dashboard",
  components: {
    ExpectedReturns,
  },
  data() {
    return {
      investments: {},
      portfolio: {},
      investmentsLoading: false,
      portfolioLoading: false,
    }
  },
  computed: {
    account() {
      return this.$store.state.account
    },
    accountId: function() {
      return this.$store.state.account.id
    },
    balance: function() {
      const currentAccount = this.$store.state.account
      if (currentAccount && currentAccount.balance) {
        return currentAccount.balance
      }
      return 0
    },
  },
  watch: {
    account: {
      immediate: true,
      handler(value, oldValue) {
        if (value?.id != oldValue?.id) {
          this.updateDashboard()
        }
      },
    },
  },
  // TODO: When moving to Vue3, refer to https://docs.gitlab.com/ee/development/fe_guide/vue3_migration.html
  mounted() {
    EventBus.$on('update-dashboard', this.updateDashboard)
  },
  unmounted() {
    EventBus.$off('update-dashboard', this.updateDashboard)
  },
  methods: {
    updateDashboard(/*data*/) {
      this.getInvestments()
      this.getPortfolio()
    },
    getInvestments() {
      if (this.accountId) {
        this.investmentsLoading = true
        this.$http
          .get(`/dashboard/${this.accountId}/investments/`)
          .then((investments) => {
            this.investmentsLoading = false
            this.investments = investments.data
          })
          .catch(() => {
            this.investmentsLoading = false
          })
      }
    },
    getPortfolio() {
      if (this.accountId) {
        this.$http
          .get(`/dashboard/${this.accountId}/portfolio/`)
          .then((response) => {
            this.portfolioLoading = false
            this.portfolio = response.data
          })
          .catch(() => {
            this.portfolioLoading = false
          })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.dashboard {
  .v-card {
    .v-list-item {
      &__title {
        max-width: 10rem;
        text-align: right;
        flex: 2 0 auto;
        margin-left: 10px;
      }
    }
  }
}

.big-number-skeleton .v-skeleton-loader__heading {
  height: 2rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.v-list-item__subtitle {
  white-space: normal;
}
</style>
