var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',{staticClass:"dashboard",attrs:{"title":_vm.$t('dashboardPage.dashboard')}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.availableFunds'))+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.availableFundsDescription')))])])],1),_c('v-card-text',{staticClass:"display-1"},[_c('base-money',{attrs:{"amount":_vm.balance || 0}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"elevation":"2","loading":_vm.investmentsLoading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.investedFunds'))+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.investedFundsDescription')))])])],1),_c('v-card-text',{staticClass:"display-1"},[_c('base-money',{attrs:{"amount":_vm.investments.total || 0}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","order":"first"}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"2","loading":_vm.portfolioLoading}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('dashboardPage.portfolioPerformance')))]),_c('v-list',[_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('dashboardPage.totalInvestments')))]),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.investments.total || 0}})],1)],1),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.returnedPrincipalPrimaryMarket'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.returnedPrincipalPrimaryMarketDescription')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.portfolio.principal || 0}})],1)],1),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.returnedPrincipalSecondaryMarket'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.returnedPrincipalSecondaryMarketDescription')))])])],1),_c('v-list-item-title',[_vm._v("0,00 €")])],1),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.earnedInterest'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.earnedInterestDescription')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.portfolio.interest || 0}})],1)],1),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.earnedPenaltyInterest'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.earnedPenaltyInterestDescription')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.portfolio.penalty || 0}})],1)],1),_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.earnedOtherFees'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.earnedOtherFeesDescription')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.portfolio.other || 0}})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('ExpectedReturns')],1)],1)],1),_c('v-col',{staticClass:"order-2",attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"elevation":"2","loading":_vm.investmentsLoading}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('dashboardPage.investmentsAllocation')))]),_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'DashboardDetail', params: { id: 'current', back: true } }}},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.currentUpToDate'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.currentUpToDateDescription')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.investments.current || 0}})],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'DashboardDetail', params: { id: 'in_grace', back: true } }}},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.gracePeriod'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.gracePeriodDescription'))+" ")])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.investments.in_grace || 0}})],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'DashboardDetail', params: { id: 'overdue_15', back: true } }}},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.overdue_15'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.overdue_15Description')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.investments.overdue_15 || 0}})],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'DashboardDetail', params: { id: 'overdue_16_30', back: true } }}},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.overdue_16_30'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.overdue_16_30Description')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.investments.overdue_16_30 || 0}})],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'DashboardDetail', params: { id: 'overdue_31_60', back: true } }}},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.overdue_31_60'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.overdue_31_60Description')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.investments.overdue_31_60 || 0}})],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'DashboardDetail', params: { id: 'overdue_60', back: true } }}},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.overdue_60'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.overdue_60Description')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.investments.overdue_60 || 0}})],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'DashboardDetail', params: { id: 'buyback', back: true } }}},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.buyback'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.buybackDescription')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.investments.buyback || 0}})],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'DashboardDetail', params: { id: 'in_recovery', back: true } }}},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.inRecovery'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.inRecoveryDescription')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.investments.in_recovery || 0}})],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'DashboardDetail', params: { id: 'defaulted', back: true } }}},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.default'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.defaultDescription')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.investments.defaulted || 0}})],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'MyInvestments' }}},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboardPage.totalInvestments'))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboardPage.totalInvestmentsDescription')))])])],1),_c('v-list-item-title',[_c('base-money',{attrs:{"amount":_vm.investments.total || 0}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }